body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root,
.app,
main,
.notranslate {
  position: fixed;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.string-value,
.string-value > div {
  display: block;
  max-width: 280px;
  wordwrap: break-word;
}

.variable-row,
.object-key-val,
.object-key,
.object-content,
.object-key-val > span > span {
  min-height: 25px;
}

.react-json-view > div {
  min-width: 200px;
}

.tableWrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  color: #494949;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
}

.table td {
  cursor: pointer;
}
